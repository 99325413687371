@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-ExtraLight.otf');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-ExtraLightItalic.otf');
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-Light.otf');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-LightItalic.otf');
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-Regular.otf');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-RegularItalic.otf');
  font-style: italic;
  font-weight: normal;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-Medium.otf');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-MediumItalic.otf');
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-SemiBold.otf');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-SemiBoldItalic.otf');
  font-style: italic;
  font-weight: 600;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-Bold.otf');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-BoldItalic.otf');
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-ExtraBold.otf');
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: Metropolis;
  src: url('./assets/fonts/Metropolis-ExtraBoldItalic.otf');
  font-style: italic;
  font-weight: 800;
}

/* Hide scrollbar for Chrome and Brave */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  margin: 0;
  font-family: Metropolis, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Change Autocomplete styles in Chrome*/
input:-internal-autofill-selected,
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

div::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
div {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.map-container {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.gm-style-iw button[title='Close'] {
  display: none !important;
}

.gm-style .gm-style-iw-tc {
  display: none !important;
}

.gm-style .gm-style-iw-d {
  overflow: hidden !important;
}

.gm-style .gm-style-iw-c {
  padding: 0 !important;
}
